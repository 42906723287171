import React from "react"
import Layout from "../components/Layout/Layout"
import styles from "./post.module.css"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon } from "react-share"
import { DiscussionEmbed } from "disqus-react"
import SEO from "../components/SEO"

const PostTemplate = ({ data }) => {
  const siteUrl = "https://www.thejollymommy.co.uk/post/"
  const { title, mainImage, category, createdAt, content: { json }, slug, seoDescription } = data.post
  const options = {
    renderNode: {
      "embedded-asset-block": (node) => {
        const url = node.data.target.fields.file["en-US"].url
        return <div className={styles.postImages} onContextMenu={(e) => e.preventDefault()}>
          <img className={`img-fluid ${styles.inlineImage}`} src={url} alt='post'/>
        </div>
      },
    },
  }
  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: slug, title },
  }
  return (
    <Layout>
      <SEO title={title} description={seoDescription} author='The Jolly Mommy' image={mainImage.fluid}
           siteUrl={`${siteUrl}${slug}`}/>
      <section className="container">
        <div className={`card mb-3 ${styles.cardMainContainer}`}>
          <Image fluid={mainImage.fluid} className={`card-img-top ${styles.imgContainer}`}
                 alt='post'/>
          <div className={`card-body ${styles.cardBodyContainer}`}>
            <h2 className="card-title text-dark">{title}</h2>
            <span><a className={styles.spanText} href="/">Jolly Mommy</a> / <a className={styles.spanText}
                                                                               href="/">{createdAt}</a> / <a
              className={styles.spanText} href="/">{category.title}</a></span>

            <hr/>

            <article>
              {documentToReactComponents(json, options)}
            </article>
            <AniLink fade to={`/post`} className={styles.cardFooterText}>Back to Posts</AniLink>

            <hr/>

            <div className={styles.share}>
              <span >Share: </span>
              <FacebookShareButton url={`${siteUrl}${slug}`} className="btn-outline-light facebook "
                                   quote={seoDescription}>
			        <span className="icon">
                <FacebookIcon size={32} round={true}/>
			          </span>
                <span className="text ml-1">Facebook</span>
              </FacebookShareButton>

              <WhatsappShareButton url={`${siteUrl}${slug}`} className="btn-outline-light whatsapp ml-2"
                                   title={title} seperator=" | ">
			        <span className="icon">
                <WhatsappIcon size={32} round={true}/>
			        </span>
                <span className="text ml-1">WhatsApp</span>
              </WhatsappShareButton>
            </div>

          </div>
        </div>
      </section>

      <div className='container'>
        <DiscussionEmbed {...disqusConfig} />
      </div>
    </Layout>
  )
}

export const query = graphql`
    query getPost($slug: String!) {
        post: contentfulPost(slug: {eq: $slug}) {
            title
            slug
            createdAt(formatString: "Do MMMM, Y")
            id: contentful_id
            content {
                json
            }
            category {
                title
            }
            mainImage {
                fluid {
                    ...GatsbyContentfulFluid_withWebp
                }
            }
        }
    }
`

export default PostTemplate
